import type { SupabaseClient } from '@supabase/supabase-js'
import type { AsyncReturnType } from 'async-return-type'
import type { Database, Json } from '~/database.types'
import { SPEAKER_INFORMATION, TRANSCRIPTION, TRANSCRIPT_PROJECTS, TRANSCRIPT_QUESTION, TRANSCRIPT_QUESTION_RESPONSE, UTTERANCES } from '~/lib/db-tables'
import { TranscriptCitationBodySchema } from '~/routes/resources.transcript.$uid.citations'
import type { ConversationBodySchema } from '~/routes/resources.transcript.$uid.conversation'

type Client = SupabaseClient<Database>

export type Transcript = Database['public']['Tables']['transcription']['Row']
export type TranscriptPreview = Pick<Transcript, "id" | "objective" | "brief" | "status" | "title">
export type Utterance = NonNullable<AsyncReturnType<typeof getTranscriptUtterances>['data']>[0]
export type Project = Database['public']['Tables']['transcript_projects']['Row']
export type TranscriptQuestion = Database['public']['Tables']['transcript_question']['Row']
export type TranscriptQuestionResponse = Database['public']['Tables']['transcript_question_response']['Row']
export type TranscriptQuestionResponsePreview = Omit<TranscriptQuestionResponse, "created_at" | "citations">

const PAGE_SIZE = 20
export interface Citation {
  quote: string
  utterance_id: string
}

interface CreateTranscriptAnalysisParams {
  transcriptionIdList: string[]
  projectId: string
  query: string
  role: ConversationBodySchema['messages'][0]['role']
}
interface CreateTranscriptAnalysisReturn {
  overview: string
  transcriptionAnswers: {
    answer: string,
    standaloneQuery: string
  }[]
}
export async function generateTranscriptAnalysis(
  { query, transcriptionIdList, role }: CreateTranscriptAnalysisParams,
): Promise<CreateTranscriptAnalysisReturn> {
  const transcriptonOverviewQueries = transcriptionIdList.map(async (id) => {
    const body: ConversationBodySchema = {
      messages: [{
        content: query,
        role,
      }],
    }
    const response = fetch(
      `${process.env.SITE_URL}/resources/transcript/${id}/conversation`,
      { method: 'POST', body: JSON.stringify(body) },
    )
    return (await response).json()
  })
  const transcriptionOverviewResponses = await Promise.all(transcriptonOverviewQueries)
  const generalOverview = await fetch(`${process.env.SITE_URL}/resources/transcript/overview`, {
    method: 'POST',
    body: JSON.stringify({
      question: query,
      transcriptionAnswers: transcriptionOverviewResponses.map(({ answer }) => answer),
    }),
  }).then(res => res.json() as Promise<string>)
  return {
    transcriptionAnswers: transcriptionOverviewResponses,
    overview: generalOverview || '',
  }
}
export async function generateTranscriptCitations(
  client: Client,
  {
    transcriptionAnswers,
    questionId
  }: {
    questionId: number;
    transcriptionAnswers: { answer: string; id: string, standaloneQuery: string }[];
  },
) {
  const transcriptCitationQueries = transcriptionAnswers.map(
    async ({ id, answer, standaloneQuery }) => {
      const body: TranscriptCitationBodySchema = {
        standaloneQuery,
        content: answer,
      };
      const response = fetch(
        `/resources/transcript/${id}/citations`,
        { method: 'POST', body: JSON.stringify(body) },
      );
      return (await response).json() as Promise<
        { citations: Citation[]; transcriptId: string }
      >;
    },
  );
  const transcriptCitationResponses = await Promise.all(
    transcriptCitationQueries,
  );
  const updateTranscriptQuestionAnswersQueries =
    transcriptCitationResponses.map(async ({ citations, transcriptId }) => {
      return client
        .from(TRANSCRIPT_QUESTION_RESPONSE)
        .update({citations: citations as unknown as Json[]})
        .eq('question_id', questionId)
        .eq('transcription_id', transcriptId)
        .single()
        .throwOnError()
    });
    await Promise.all(
      updateTranscriptQuestionAnswersQueries,
    );
  return {
    citations: transcriptCitationResponses,
  };
}
export async function createTranscriptQuestion(
  client: Client,
  {
    projectId,
    question,
    overview,
    userId,
  }: {
    projectId: string
    question: string
    overview?: string
    userId?: string
  },
) {
  return client
    .from(TRANSCRIPT_QUESTION)
    .insert({ project_id: projectId, question, overview, user_id: userId })
    .select('id')
    .single()
    .throwOnError()
}
export async function createTranscriptQuestionResponse(
  client: Client,
  {
    questionId,
    content,
    transcriptionId,
    citations,
  }:
  {
    questionId: number
    content: string
    transcriptionId: string
    citations?: Citation[]
  },
) {
  return client
    .from(TRANSCRIPT_QUESTION_RESPONSE)
    .insert({
      question_id: questionId,
      content,
      transcription_id: transcriptionId,
      citations: (citations as unknown as Json[]) || [],
    })
    .select('id')
    .single()
    .throwOnError()
}
export async function createTranscriptProject(client: Client, project: Omit<Project, 'id' | 'created_at'>) {
  return client.from(TRANSCRIPT_PROJECTS).insert({ ...project }).select('id').single().throwOnError()
}
export async function createTranscript(
  client: Client,
  transcript: Omit<Transcript, 'id' | 'created_date' | 'status' | 'summary'>,
) {
  const { organization_id, title, brief, objective, meeting_url, created_by, s3key, type, assembly_id, audio_s3_path, upload_media_type, project_id } = transcript
  if (!organization_id) {
    throw new Error('No orgID')
  }
  return client
    .from(TRANSCRIPTION)
    .insert({
      organization_id,
      title,
      brief,
      objective,
      meeting_url,
      s3key,
      type,
      assembly_id,
      created_by,
      upload_media_type,
      audio_s3_path,
      project_id,
    })
    .select(`id`)
    .single()
    .throwOnError()
}
export async function getAllTranscriptQuestions(
  client: Client,
  { projectId }: { projectId: string },
) {
  return client
    .from(TRANSCRIPT_QUESTION)
    .select('*')
    .eq('project_id', projectId)
}

export async function getTranscriptQuestionAnswerCitations(
  client: Client,
  params: {
    transcriptionId: string;
    questionId: number;
  },
) {
  return client
    .from(TRANSCRIPT_QUESTION_RESPONSE)
    .select('citations')
    .eq('transcription_id', params.transcriptionId)
    .eq("question_id", params.questionId)
    .single()
}
export async function getAllTranscriptQuestionAnswers(
  client: Client,
  { questionId }: { questionId: number },
) {
  return client
    .from(TRANSCRIPT_QUESTION_RESPONSE)
    .select('*')
    .eq('question_id', questionId)
    .throwOnError()
}
export async function getAllTranscriptQuestionAnswersPreview(
  client: Client,
  {questionId, projectId}: {questionId: number, projectId: string}
) {
  return client
    .from(TRANSCRIPT_QUESTION_RESPONSE)
    .select(`
      id, content, transcription_id, question_id,
      transcript_question(id, question, project_id)
      `)
    .eq('question_id', questionId)
    .eq('transcript_question.project_id', projectId)
    .throwOnError()
}
export async function getTranscriptProjects(client: Client, params: {
  orgId: number
  fileId?: string
  pageIndex: number
  perPage?: number
}) {
  const { orgId, perPage, pageIndex } = params
  const { startOffset, endOffset } = getPaginationOffsets(pageIndex, perPage)
  if (!orgId) {
    throw new Error('No orgID')
  }
  const query = client
    .from(TRANSCRIPT_PROJECTS)
    .select(`
        id,
        title,
        objective,
        brief,
        created_at
    `, {
      count: 'exact',
    })
    .eq('organization_id', orgId)
    .order('created_at', { ascending: false })
    .range(startOffset, endOffset)
    .throwOnError()

  return query
}
export async function getTranscriptStatus(
  client: Client,
  { transcriptionId }: { transcriptionId: string },
) {
  return client
    .from(TRANSCRIPTION)
    .select('status')
    .eq('id', transcriptionId)
    .single()
}

export async function getTranscriptProjectsLabel(client: Client, { orgId }: { orgId: number }) {
  if (!orgId) {
    throw new Error('No orgID')
  }
  const query = client
    .from(TRANSCRIPT_PROJECTS)
    .select(`
        id,
        title
    `, {
      count: 'exact',
    })
    .eq('organization_id', orgId)
    .order('created_at', { ascending: false })
    .throwOnError()

  return query
}
export async function getTranscripts(
  client: Client,
  params: {
    orgId: number
    fileId?: string
    pageIndex: number
    perPage?: number
    groupPages?: boolean
  },
) {
  const { orgId, perPage, pageIndex, groupPages } = params
  const { startOffset, endOffset } = getPaginationOffsets(pageIndex, perPage)
  if (!orgId) {
    throw new Error('No orgID')
  }
  const groupPagesStartOffset = 0
  const groupPagesEndOffset = (pageIndex * (perPage || 0)) + (perPage || 0)
  const query = client
    .from(TRANSCRIPTION)
    .select(`
        id,
        organization_id,
        title,
        brief,
        created_date
    `, {
      count: 'exact',
    })
    .order('created_date', { ascending: false })
    .eq('organization_id', orgId)
    .range(groupPages ? groupPagesStartOffset : startOffset, groupPages ? groupPagesEndOffset : endOffset)
    .throwOnError()

  return query
}
export async function getDefaultTranscriptions(client: Client, params: {
  orgId: number
  userId: string,
  from?: number,
  to?:number
}) {
  const request = client
    .from(TRANSCRIPTION)
    .select()
    .is('project_id', null)
    .eq('created_by', params.userId)
    .eq('organization_id', params.orgId)
    .order('created_date', { ascending: false })
    .throwOnError();
  if (params?.from || params?.to) {
    return request.range(params?.from ?? 0, params?.to ?? 0);
  }
  return request;
}
export async function getProjectTranscripts(client: Client, params: {
  projectId: string
  orgId: number
}) {
  return client
    .from(TRANSCRIPTION)
    .select()
    .eq('project_id', params.projectId)
    .eq('organization_id', params.orgId)
    .order('created_date', { ascending: false })
    .throwOnError()
}
export async function getProjectTranscriptsById(client: Client, params: {
  transcriptsIdList: string[]
}) {
  return client
    .from(TRANSCRIPTION)
    .select('*')
    .in('id', params.transcriptsIdList)
    .throwOnError()
}
export async function getProjectTranscriptsByIdPreview(client: Client, params: {
  transcriptsIdList: string[]
}) {
  return client
    .from(TRANSCRIPTION)
    .select('id, objective, brief, status, title')
    .in('id', params.transcriptsIdList)
    .throwOnError()
}
export async function getTranscriptSpeakers(
  client: Client,
  params: {
    transcriptId: string
  },
) {
  if (!params.transcriptId) {
    throw new Error('No transcriptId')
  }
  return client
    .from(SPEAKER_INFORMATION)
    .select()
    .eq('transcript_id', params.transcriptId)
    .throwOnError()
}
export async function getTranscriptAudioPath(
  client: Client,
  {
    transcriptId
  }: {
    transcriptId: string
  }
) {
  return client
     .from(TRANSCRIPTION)
     .select("audio_s3_path")
      .eq("id", transcriptId)
      .single()
}
export async function getTranscriptProject(client: Client, params: {
  id: string
}) {
  if (!params.id) {
    throw new Error('No id')
  }
  return client
    .from(TRANSCRIPT_PROJECTS)
    .select(`
      id,
      title,
      brief,
      objective,
      created_at
    `)
    .eq('id', params.id)
    .single()
    .throwOnError()
}
export async function getTranscript(
  client: Client,
  params: {
    id: string
  },
) {
  if (!params.id) {
    throw new Error('No id')
  }
  return client
    .from(TRANSCRIPTION)
    .select(`
      id,
      organization_id,
      title,
      brief,
      objective,
      type,
      status,
      bot_id,
      audio_s3_path,
      transcript_analysis(id, summary, topics, key_insights)
    `)
    .eq('id', params.id)
    .single()
    .throwOnError()
}

export async function getTranscriptUtterances(
  client: Client,
  params: {
    transcriptId: string
  },
) {
  if (!params.transcriptId) {
    throw new Error('No transcriptId')
  }
  return client
    .from(UTTERANCES)
    .select(`
      id,
      transcript_id,
      speaker,
      text,
      start_ms,
      end_ms,
      confidence,
      edited_at,
      speaker_information(label, id, age, gender, type, location)
    `)
    .eq('transcript_id', params.transcriptId)
    .order('start_ms', { ascending: true })
    .throwOnError()
}
export function updateTranscriptQuestionResponse(
  client: Client,
  {
    transcriptQuestionResponseItem,
  }: {
    transcriptQuestionResponseItem: {id: number} & Partial<
      TranscriptQuestionResponse
    >;
  },
) {
 const { id, ...item } = transcriptQuestionResponseItem;
  return client
    .from(TRANSCRIPT_QUESTION_RESPONSE)
    .update(item)
    .eq('id', id)
    .single()
    .throwOnError();
  
}
export function updateTranscriptUtterance(
  client: Client,
  utterance: {
    id: number
    text: string
    edited_by?: string
  },
) {
  const { id, text, edited_by } = utterance
  return client
    .from(UTTERANCES)
    .update({
      text,
      edited_at: new Date().toISOString(),
      edited_by: edited_by || null,
    })
    .match({
      id,
    })
    .throwOnError()
}

function getPaginationOffsets(pageIndex: number, perPage?: number) {
  const pageSize = perPage || PAGE_SIZE
  const startOffset = pageIndex * pageSize
  const endOffset = startOffset + pageSize

  return {
    startOffset,
    endOffset,
  }
}
export function deleteProjectQuestion(client: Client, {
  questionId
}: {
  questionId: number
}) {
  return client
    .from(TRANSCRIPT_QUESTION)
    .delete()
    .eq('id', questionId)
    .single();
          
}
export function deleteTranscript(
  client: Client,
  { id }: { id: string },
) {
  return client.from(TRANSCRIPTION).delete().match({
    id,
  }).throwOnError()
}
export async function deleteProject(
  client: Client,
  { id }: { id: string },
) {
  return client.from(TRANSCRIPT_PROJECTS).delete().match({
    id,
  }).throwOnError()
}
export function updateTranscript(
  client: Client,
  transcript: Partial<Transcript> & { id: string },
) {
  const { id, ...data } = transcript
  return client
    .from(TRANSCRIPTION)
    .update({
      ...data,
    })
    .match({
      id: transcript.id,
    })
    .throwOnError()
}
export async function updateProject(
  client: Client,
  project: Partial<Project>,
) {
  return client
    .from(TRANSCRIPT_PROJECTS)
    .update({
      ...project,
    })
    .match({
      id: project.id,
    })
    .throwOnError()
}
